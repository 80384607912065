<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="2" lg="2">
        <h4 class="lfont ms-2 mt-2">{{ $t("meeting.new_meeting_nav") }}</h4>
      </v-col>
      <v-col cols="12" md="2" lg="2">
        <v-btn class="mr-md-2 btn-create lfont btn" color="info" @click="onCreate">
          <v-icon left>
            mdi-account-multiple-plus
          </v-icon>
          {{ $t("meeting.btn_create") }}</v-btn>
      </v-col>

      <v-col cols="12">
        <div class="section-form-content">
          <div class="block-content">
            <table class="table table-view" v-if="listEmployeeMeeting.length > 0">
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t("meeting.table.create") }}
                  </th>
                  <th class="text-left">
                    {{ $t("meeting.table.title") }}
                  </th>
                  <th class="text-left">
                    {{ $t("meeting.table.place") }}
                  </th>
                  <th class="text-left">
                    {{ $t("meeting.table.detail") }}
                  </th>
                  <th class="text-left"></th>
                  <th class="text-left"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in listEmployeeMeeting" :key="index">
                  <td>{{ item.created_at }}</td>
                  <td>{{ item.title }}</td>
                  <td>{{ item.place }}</td>
                  <td>{{ item.detail }}</td>
                  <td>
                    <div>
                      <v-btn color="secondary" small fab dark @click="modalShowDetail(item.id)">
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </div>
                  </td>
                  <td>
                    <v-btn class="btn-create lfont btn" color="blue-grey" small @click="joinMeeting(item.id)">
                      {{ $t("meeting.btn_join") }}</v-btn>
                    <v-btn class="btn-create lfont btn ms-2 mr-2" color="error" small @click="rejectMeeting(item.id)">
                      {{ $t("meeting.btn_reject") }}</v-btn>
                  </td>
                </tr>
              </tbody>
            </table>
            <defaultTableNoResult v-else />
            <Loading v-if="isLoading" />
            <ShowModal @success="fetchEmployeeMeeting()" @close="dialog = false" :id="id" :dialog="dialog" />
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ShowModal from "./ShowDetail/ShowDetail.vue";
import Loading from "@/components/Loading";
import Pagination from "../../../components/Paginate/Pagination";
import defaultTableNoResult from "@/components/defaultTableNoResult";

export default {
  components: {
    ShowModal,

    Loading,
    Pagination,
    defaultTableNoResult,
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
      id: "",
      listEmployeeMeeting: [],
    };
  },
  methods: {
    onCreate() {
      this.$router
        .push({
          name: "employee_meeting.add",
        })
        .catch(() => { });
    },
    modalShowDetail(id) {
      this.dialog = true;
      this.id = id;
    },
    searchFilterItem() {
      this.fetchEmployeeMeeting();
      // this.pagination.current_page = 1;
    },
    fetchEmployeeMeeting() {
      this.isLoading = true;
      this.$axios
        .get(`/meeting/new`)
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false;
            this.listEmployeeMeeting = res.data.data;
            // this.pagination = res.data.data.pagination;
            // if (!this.listEmployeeMeeting.length > 0) {
            //   this.pagination.current_page = this.pagination.current_page - 1;
            // }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    joinMeeting(id) {
      this.$axios
        .put(`/meeting/join/all/${id}`)
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
            this.$emit("close");
            this.$emit("success");
            this.fetchEmployeeMeeting();
            // this.$router
            //   .push({
            //     name: "employee_task_allocation.index",
            //   })
            //   .catch(() => {});
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },
    rejectMeeting(id) {
      this.$axios
        .put(`/meeting/reject/all/${id}`)
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
            this.$emit("close");
            this.$emit("success");
            this.fetchEmployeeMeeting();
            // this.$router
            //   .push({
            //     name: "employee_task_allocation.index",
            //   })
            //   .catch(() => {});
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },
  },
  created() {
    this.fetchEmployeeMeeting();
  },
};
</script>

<style lang="scss" scoped>
.custom_table {
  margin-top: 10px;
  border: 1px solid rgb(131, 128, 128);
  width: auto;
  height: auto;
  padding: 5px;
}
</style>
