<template>
  <div class="content-layout">
    <v-dialog v-model="showModal" persistent width="600px">
      <v-card>
        <v-card-title>
          <v-row
            v-for="(item, index) in listEmployeeMeetingDetail"
            :key="index"
          >
            <v-col cols="12" md="12" lg="12">
              <v-card elevation="2" class="pa-8 mt-4">
                <v-row>
                  <v-col cols="6" md="6" lg="6" sm="6">
                    <p>{{ item.title }}</p>
                  </v-col>
                  <v-col
                    cols="6"
                    md="6"
                    lg="6"
                    sm="6"
                    class="d-flex justify-end"
                  >
                    <v-btn
                      style="color: #fff;"
                      class="btn-create lfont mr-2"
                      color="blue-grey"
                      small
                      @click="joinAll()"
                    >
                      {{ $t("meeting.btn_join") }}</v-btn
                    >
                    <v-btn
                      class="btn-create lfont"
                      color="error"
                      small
                      @click="rejectAll()"
                    >
                      <!-- @click="modalReject(item.id)" -->
                      {{ $t("meeting.btn_reject") }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card>
            </v-col>

            <v-col cols="12" md="12" lg="12">
              <v-card elevation="2" class="pa-8 mt-4 lfont">
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    sm="6"
                    class="d-flex justify-end"
                  >
                    {{ $t("meeting.modal.place") }} :
                  </v-col>
                  <v-col cols="12" md="6" lg="6" sm="6">
                    {{ item.place }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    sm="6"
                    class="d-flex justify-end"
                  >
                    {{ $t("meeting.modal.detail") }} :
                  </v-col>
                  <v-col cols="12" md="6" lg="6" sm="6">
                    {{ item.detail }}
                  </v-col>
                </v-row>
                <v-row v-for="(time, i) in item.meeting_time" :key="'A' + i">
                  <v-col
                    cols="12"
                    md="9"
                    lg="9"
                    sm="9"
                    class="d-flex justify-end"
                  >
                    {{ time.meet_date }} {{ time.start }} - {{ time.end }}
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                    lg="3"
                    sm="3"
                    class="d-flex justify-end"
                  >
                    <span>
                      <v-avatar
                        v-if="time.status == 'join'"
                        class="mr-3"
                        color="success"
                        size="25"
                        @click="join(time.id)"
                        ><v-icon style="color:#fff;cursor: pointer;">
                          mdi-check
                        </v-icon></v-avatar
                      >
                      <v-avatar
                        v-if="time.status == 'reject'"
                        class="mr-3"
                        color="secondary"
                        size="25"
                        @click="join(time.id)"
                        ><v-icon style="color:#fff;cursor: pointer;">
                          mdi-check
                        </v-icon></v-avatar
                      >
                      <v-avatar
                        v-if="time.status == 'pendding'"
                        class="mr-3"
                        color="secondary"
                        size="25"
                        @click="join(time.id)"
                        ><v-icon style="color:#fff;cursor: pointer;">
                          mdi-check
                        </v-icon></v-avatar
                      >
                      <v-avatar
                        v-if="time.status == 'pendding'"
                        color="secondary"
                        size="25"
                        @click="reject(time.id)"
                        ><v-icon style="color:#fff;cursor: pointer;">
                          mdi-close
                        </v-icon></v-avatar
                      >
                      <v-avatar
                        v-if="time.status == 'reject'"
                        color="error"
                        size="25"
                        @click="reject(time.id)"
                        ><v-icon style="color:#fff;cursor: pointer;">
                          mdi-close
                        </v-icon></v-avatar
                      >
                      <v-avatar
                        v-if="time.status == 'join'"
                        color="secondary"
                        size="25"
                        @click="reject(time.id)"
                        ><v-icon style="color:#fff;cursor: pointer;">
                          mdi-close
                        </v-icon></v-avatar
                      >
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <h5
                      class="text-center mt-3"
                      style="color: rgb(50, 50, 194);"
                    >
                      {{ $t("meeting.modal.attendance") }}
                    </h5>
                  </v-col>
                </v-row>
                <v-row v-for="(emp, id) in item.emloyees" :key="id">
                  <v-col cols="12">
                    <span class="text-center"
                      >{{ emp.name }} {{ emp.surname }}</span
                    ><br />
                    <span
                      v-for="(emp_time, dex) in emp.employee_meeting_time"
                      :key="dex"
                    >
                      <span v-if="emp_time.status == 'reject'">
                        <v-avatar color="error" size="25">
                          <span style="color: #fff;font-size: 70%;">
                            {{ emp_time.meet_day }}</span
                          >
                        </v-avatar>
                      </span>
                      <span v-if="emp_time.status == 'join'">
                        <v-avatar color="success" size="25">
                          <span style="color: #fff;font-size: 70%;">
                            {{ emp_time.meet_day }}</span
                          >
                        </v-avatar>
                      </span>
                      <span v-if="emp_time.status == 'pendding'">
                        <v-avatar color="secondary" size="25">
                          <span style="color: #fff;font-size: 70%;">
                            {{ emp_time.meet_day }}</span
                          >
                        </v-avatar>
                      </span>
                    </span>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="error" text class="lfont" @click="closeModal">
            {{ $t("meeting.btn_close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { emit } from "process";

export default {
  props: ["id", "dialog"],
  data() {
    return {
      listEmployeeMeetingDetail: [],
      remark: "",
      server_errors: {
        remark: "",
      },
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },

    fetchEmployeeMeetingDetail() {
      this.isLoading = true;
      this.$axios
        .get(`/meeting/detail/${this.id}`)
        .then((res) => {
          if (res.status === 200) {
            console.log("detail", res);
            this.isLoading = false;
            this.listEmployeeMeetingDetail = res.data.data;
            // console.log(this.listEmployeeMeetingDetail.title);
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },

    join(id) {
      this.$axios
        .put(`/meeting/join/${id}`)
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
            this.fetchEmployeeMeetingDetail();
            // this.$emit("close");
            // this.$emit("success");
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },
    joinAll() {
      this.$axios
        .put(`/meeting/join/all/${this.id}`)
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
            this.fetchEmployeeMeetingDetail();
            // this.$emit("close");
            // this.$emit("success");
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },
    reject(id) {
      this.$axios
        .put(`/meeting/reject/${id}`)
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
            this.fetchEmployeeMeetingDetail();
            // this.$emit("close");
            // this.$emit("success");
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },
    rejectAll() {
      this.$axios
        .put(`/meeting/reject/all/${this.id}`)
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
            this.fetchEmployeeMeetingDetail();
            // this.$emit("close");
            // this.$emit("success");
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },
  },
  computed: {
    showModal: function() {
      this.fetchEmployeeMeetingDetail();
      return this.dialog;
    },
  },
  created() {
    // this.fetchEmployeeMeetingDetail();
  },
};
</script>

<style scoped lang="scss">
.content-layout {
  padding: 20px;
  font-family: "Noto Sans Lao" !important;
}
.btn {
  padding: 30px !important;
}
.btn-save {
  color: white !important;
  margin-right: 30px;
}
.user {
  color: blue;
}
</style>
